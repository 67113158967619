import { Clickable, ClickHandler } from 'dg-web-shared/ui/Clickable';
import * as Icons24 from 'dg-web-shared/ui/icons/Icons24';
import * as ReactDOM from 'react-dom';
import { css } from '@emotion/css';
import { Colors, Typo } from './vars';
import React from 'react';
import { Icon24 } from 'dg-web-shared/ui/icons/Icon';

const SLIDEIN_ANIMATION_TIME = 300;

type ContainerProps<P> = P & { open: boolean };
type ContainerState<P> = ContainerProps<P> & { shouldRender: boolean };

export interface SlideInProps {
    open: boolean;
    onClose: () => void;
    title: string | JSX.Element;
    children?: React.ReactNode;
}

function generatePortalSlideInComponent(containerId: string) {
    return function <P extends SlideInProps>(Comp: React.ComponentType<P>) {
        return class extends React.Component<
            ContainerProps<P>,
            ContainerState<P>
        > {
            static displayName = 'SlideInComponent';
            constructor(props: ContainerProps<P>) {
                super(props);
                this.state = {
                    ...props,
                    shouldRender: props.open,
                };
            }

            UNSAFE_componentWillReceiveProps(nextProps: ContainerProps<P>) {
                if (nextProps.open) {
                    this.setState({
                        ...nextProps,
                        shouldRender: true,
                    });
                }

                if (!nextProps.open && this.state.shouldRender) {
                    this.setState({ open: false });
                    setTimeout(() => {
                        /*
                        generics and strict set state typing do not work out here
                        */
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        this.setState<any>({ shouldRender: false });
                    }, SLIDEIN_ANIMATION_TIME);
                }
            }

            render(): JSX.Element | null {
                return this.state.shouldRender
                    ? ReactDOM.createPortal(
                          <SelfOpeningSlideIn {...this.state}>
                              <Comp {...this.state} />
                          </SelfOpeningSlideIn>,
                          document.getElementById(containerId) as HTMLElement,
                      )
                    : null;
            }
        };
    };
}

interface SelfOpeningSlideInState {
    open: boolean;
}

const staticStyles = css({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'white',
    boxShadow: '0 1px 4px rgba(0,0,0,0.3)',
    transitionProperty: 'transform',
    transitionDuration: '300ms',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    willChange: 'transform',
});

class SelfOpeningSlideIn extends React.Component<
    SlideInProps,
    SelfOpeningSlideInState
> {
    state: SelfOpeningSlideInState = { open: false };

    componentDidMount() {
        if (this.props.open) {
            setTimeout(() => {
                this.setState({ open: true });
            }, 0);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: SlideInProps) {
        this.setState({ open: nextProps.open });
    }

    render() {
        return (
            <div
                className={css(staticStyles, {
                    transform: !this.state.open
                        ? 'translateX(100%)'
                        : 'translateX(0)',
                })}
            >
                <div
                    className={css({
                        position: 'absolute',
                        height: '48px',
                        top: 0,
                        left: 0,
                        right: 0,
                        background: Colors.darkBlue,
                        display: 'flex',
                        alignItems: 'center',
                    })}
                >
                    <div
                        className={css({
                            ...Typo.robotoLight,
                            fontSize: '22px',
                            flex: 1,
                            color: Colors.white,
                            userSelect: 'text',
                            marginLeft: '23px',
                        })}
                    >
                        {this.props.title}
                    </div>
                    <SlideInHeaderClose onClose={() => this.props.onClose()} />
                </div>
                <div
                    className={css({
                        position: 'absolute',
                        top: '48px',
                        bottom: '0px',
                        width: '100%',
                        overflowY: 'auto',
                    })}
                >
                    <div
                        className={css({
                            position: 'relative',
                            height: '100%',
                            overflowX: 'hidden',
                            overflowY: 'auto',
                            WebkitOverflowScrolling: 'touch',
                        })}
                    >
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export const slideInPortal = generatePortalSlideInComponent('slidein');

interface SlideInHeaderCloseProps {
    onClose: ClickHandler;
}

export const SlideInHeaderClose = (p: SlideInHeaderCloseProps) => {
    return (
        <Clickable
            element="div"
            className={css({
                color: Colors.white,
                width: '48px',
            })}
            onClick={p.onClose}
        >
            <Icon24 icon={Icons24.clear_b} />
        </Clickable>
    );
};

interface SlideInItemProps {
    children?: React.ReactNode;
    onClick: () => void;
    selected: boolean;
}

export const SlideInItem = (p: SlideInItemProps): JSX.Element => {
    return (
        <Clickable
            element="div"
            className={css({
                ...Typo.robotoLight,
                fontSize: '18px',
                padding: '16px 24px',
                color: p.selected ? Colors.white : Colors.darkBlue,
                backgroundColor: p.selected ? Colors.lightBlue : 'transparent',
            })}
            onClick={p.onClick}
        >
            {p.children}
        </Clickable>
    );
};
