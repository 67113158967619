import * as Flux from 'dg-web-shared/lib/Flux';
import { Response, StatusCode } from 'dg-web-shared/lib/HttpResponse';
import * as Sentry from '@sentry/browser';

export enum Error {
    NOT_PROVIDED,
    VIGNETTE_EXISTS,
}

export interface State<D> {
    pending: boolean;
    statusCode: StatusCode;
    errorMessage: Error | null;
    successData: D | null;
    attempts: number;
}

function captureError<D>(_s: State<D>, res: Response): void {
    if (!res.statusCode.cls.error) {
        return;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload = (<any>res.request)._data;
    Sentry.withScope(scope => {
        scope.setContext('request data', {
            value: {
                payload: JSON.stringify(payload, null, 2),
                body: JSON.stringify(res.body, null, 2),
            },
        });
        Sentry.captureMessage(
            `${res.request.url} ${res.statusCode.statusCode}`,
        );
    });
}

abstract class StateSlice<D> extends Flux.StateSlice<State<D>> {
    abstract parseBody(body: unknown): D;
    sideEffects(): void {
        return;
    }

    getInitialState(): State<D> {
        return {
            pending: false,
            statusCode: new StatusCode(null),
            errorMessage: null,
            successData: null,
            attempts: 0,
        };
    }

    writeResponse(res: Response): void {
        this.set((s: State<D>): State<D> => {
            if (res.pending) {
                s.pending = true;
            } else {
                s.pending = false;
                s.statusCode = res.statusCode;
                s.attempts += 1;

                if (res.statusCode.cls.success) {
                    s.successData = this.parseBody(res.body);
                } else if (res.statusCode.cls.error) {
                    if (res.body) {
                        s.errorMessage =
                            Error[res.body.message as keyof typeof Error];
                    }
                    if (s.statusCode.cls.error) {
                        captureError(s, res);
                    }
                }
            }
            return s;
        });
    }
}

interface GeneratorFunctions<D> {
    get: (store: Flux.Store) => State<D>;
    reset: (store: Flux.Store) => void;
    setResponse: (store: Flux.Store, res: Response) => void;
}

export const generate = <D>(
    key: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parseBody: (body: any) => D,
): GeneratorFunctions<D> => {
    class GenServerStateSlice extends StateSlice<D | null> {
        key(): string {
            return key;
        }
        getEmptyData(): D | null {
            return null;
        }
        sideEffects(_store?: Flux.Store): void {
            return;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        parseBody(body: any): D | null {
            if (body) {
                return parseBody(body);
            } else {
                return null;
            }
        }
    }

    return {
        get: (store: Flux.Store): State<D> =>
            new GenServerStateSlice(store).state as State<D>,
        reset: (store: Flux.Store): void =>
            new GenServerStateSlice(store).reset(),
        setResponse: (store: Flux.Store, res: Response): void =>
            new GenServerStateSlice(store).writeResponse(res),
    };
};
