import { localState, selectState } from 'dg-web-shared/lib/Flux';
import { css } from '@emotion/css';
import {
    generateEnforcementContextualServerReadStateSlice,
    generateEnforcementServerWriteStateSlice,
} from '../state/EnforcementStateSlices';
import * as superagent from 'superagent';
import { makeEnforcementUrl } from '../Http';
import { TextButton } from './TextButton';
import { AlertBoxed, PendingOrErrorModal } from './Modals';
import { texts } from '../i18n';
import { RequestStatus } from 'dg-web-shared/lib/ServerRequestStateSlices';
import { useRef, useState } from 'react';
import QrReader from 'react-qr-reader';

namespace QrCodeDataState {
    export type Context = {
        qrCode: string;
    };

    export interface BaseResponse {
        status:
            | QrCodeStatus.unpaired
            | QrCodeStatus.qrCodeUnknown
            | QrCodeStatus.alreadyPairedPermit
            | QrCodeStatus.alreadyPairedOtherOperator;
        pairingAllowed: boolean;
    }

    export interface AlreadyPairedResponse {
        status: QrCodeStatus.alreadyPaired;
        zoneName: string;
        zoneId: number;
        pairingAllowed: boolean;
    }

    export type Response = BaseResponse | AlreadyPairedResponse;

    export enum QrCodeStatus {
        unpaired = 'unpaired',
        alreadyPaired = 'alreadyPaired',
        alreadyPairedOtherOperator = 'alreadyPairedOtherOperator',
        alreadyPairedPermit = 'alreadyPairedPermit',
        qrCodeUnknown = 'qrCodeUnknown',
    }

    export const { get, refetchSameContext } =
        generateEnforcementContextualServerReadStateSlice<Context, Response>({
            key: 'qr-code-data',
            req: context =>
                superagent
                    .get(makeEnforcementUrl('twint-qr-code', 'prod'))
                    .query({ code: context.qrCode }),
        });
}

namespace PairRequestState {
    export interface Payload {
        code: string;
        zoneId: number;
    }

    export interface OkResponse {
        status: 'ok';
    }

    export const { get, send, reset } =
        generateEnforcementServerWriteStateSlice<Payload, OkResponse>({
            key: 'twint-pair-request',
            req: (payload?: Payload) =>
                superagent
                    .post(makeEnforcementUrl('twint-qr-code/pair', 'prod'))
                    .send(payload),
            useSessionToken: true,
            onResponse: (store, req) => {
                if (req.status === 'success') {
                    store.update(store =>
                        QrCodeDataState.refetchSameContext(store, true),
                    );
                }
            },
        });
}

function QrCodeScannerWithLegacyMode({
    setQrCode,
}: {
    setQrCode: (data: string | null) => void;
}) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ref = useRef<any>(null);
    const [legacyMode, setLegacyMode] = useState(false);

    return (
        <div>
            <QrReader
                delay={500}
                ref={ref}
                showViewFinder={false}
                onError={e => {
                    console.error(e);
                    setLegacyMode(true);
                }}
                onScan={setQrCode}
                legacyMode={legacyMode}
                style={{
                    width: '57%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
            />
            {legacyMode && (
                <div
                    className={css({
                        display: 'flex',
                        justifyContent: 'center',
                    })}
                >
                    <TextButton
                        label={'Bild von QR-Code hochladen'}
                        onClick={() => {
                            ref.current.openImageDialog();
                        }}
                    />
                </div>
            )}
        </div>
    );
}
export const QrCodeScanner = localState<
    { selectedZone: number | null; zoneName: string | null },
    { qrCode: string | null }
>(
    { qrCode: '' },
    selectState(
        (store, props) => {
            return {
                qrCodeData: QrCodeDataState.get(
                    store,
                    props.state.qrCode ? { qrCode: props.state.qrCode } : null,
                ),
                pairRequest: PairRequestState.get(store),
            };
        },
        (p): JSX.Element => {
            const qrCodeParts = p.state.qrCode
                ? p.state.qrCode.split('#')
                : ['-'];

            const pairingPossible =
                qrCodeParts.length > 1 &&
                p.pairRequest.status === RequestStatus.NEVER_EXECUTED &&
                p.qrCodeData.data &&
                p.qrCodeData.data.pairingAllowed &&
                (p.qrCodeData.data.status ===
                    QrCodeDataState.QrCodeStatus.unpaired ||
                    (p.qrCodeData.data.status ===
                        QrCodeDataState.QrCodeStatus.alreadyPaired &&
                        p.selectedZone !== p.qrCodeData.data.zoneId));

            return (
                <div className={css({ width: '100%' })}>
                    <PendingOrErrorModal
                        slices={[p.pairRequest, p.qrCodeData]}
                    />
                    <QrCodeScannerWithLegacyMode
                        setQrCode={(qrCode: string | null | undefined) => {
                            if (qrCode) {
                                if (qrCode !== p.state.qrCode) {
                                    p.setState({ qrCode: qrCode });
                                    p.update(PairRequestState.reset);
                                }
                            }
                        }}
                    />
                    <div
                        className={css({
                            fontFamily: 'roboto',
                            fontSize: 20,
                            margin: '16px 0',
                            textAlign: 'center',
                        })}
                    >
                        {texts.qrCode}: {qrCodeParts[qrCodeParts.length - 1]}
                    </div>
                    <div
                        className={css({
                            fontFamily: 'roboto',
                            margin: '16px 30px',
                            textAlign: 'center',
                        })}
                    >
                        <p>
                            {p.qrCodeData.status === RequestStatus.SUCCESS &&
                                qrCodeStatusTexts(
                                    p.qrCodeData.data,
                                    p.selectedZone,
                                )}
                        </p>
                    </div>
                    <div
                        className={css({
                            fontFamily: 'roboto',
                            fontSize: 20,
                            margin: '16px 0',
                            textAlign: 'center',
                        })}
                    >
                        <p>
                            {p.qrCodeData.status === RequestStatus.SUCCESS &&
                                ((): string => {
                                    switch (p.qrCodeData.data.status) {
                                        case QrCodeDataState.QrCodeStatus
                                            .alreadyPaired:
                                            return p.selectedZone !==
                                                p.qrCodeData.data.zoneId
                                                ? p.qrCodeData.data.zoneName
                                                : '';
                                        default:
                                            return '';
                                    }
                                })()}
                        </p>
                    </div>
                    <div
                        className={css({
                            display: 'flex',
                            margin: '16px 0',
                            justifyContent: 'center',
                        })}
                    >
                        {pairingPossible && (
                            <TextButton
                                label={texts.cancel}
                                onClick={() => {
                                    p.update(PairRequestState.reset);
                                    p.setState({ qrCode: null });
                                }}
                            />
                        )}
                        {pairingPossible && (
                            <AlertBoxed
                                confirmLabel={texts.confirm}
                                confirmColor={'blue'}
                                onConfirm={() => {
                                    p.update(PairRequestState.send, {
                                        code: p.state.qrCode || '',
                                        zoneId: p.selectedZone || -1,
                                    });
                                    p.setState({ qrCode: null });
                                }}
                                trigger={
                                    <TextButton
                                        label={
                                            p.qrCodeData.data &&
                                            p.qrCodeData.data.status ===
                                                QrCodeDataState.QrCodeStatus
                                                    .alreadyPaired
                                                ? texts.overrideQrCode
                                                : texts.pairQrCode
                                        }
                                        onClick={() => null}
                                    />
                                }
                            >
                                <p>
                                    {p.qrCodeData.data &&
                                    p.qrCodeData.data.status ===
                                        QrCodeDataState.QrCodeStatus
                                            .alreadyPaired
                                        ? texts.attention
                                        : ''}
                                </p>
                                <p>
                                    {p.qrCodeData.data &&
                                    p.qrCodeData.data.status ===
                                        QrCodeDataState.QrCodeStatus
                                            .alreadyPaired
                                        ? texts.overrideQrCodeInfo
                                        : texts.confirmPair}
                                </p>
                                <p>
                                    <strong>{p.zoneName || ''}</strong>
                                </p>
                            </AlertBoxed>
                        )}
                    </div>
                </div>
            );
        },
    ),
);

export function qrCodeStatusTexts(
    qrCodeData: QrCodeDataState.Response,
    selectedZone: number | null,
) {
    switch (qrCodeData.status) {
        case QrCodeDataState.QrCodeStatus.unpaired:
            return texts.unpaired;
        case QrCodeDataState.QrCodeStatus.alreadyPaired:
            return qrCodeData.zoneId !== selectedZone
                ? texts.alreadyPaired
                : texts.alreadyPairedCurrentZone;
        case QrCodeDataState.QrCodeStatus.alreadyPairedPermit:
            return texts.alreadyPairedPermit;
        case QrCodeDataState.QrCodeStatus.alreadyPairedOtherOperator:
            return texts.alreadyPairedOtherOperator;
        case QrCodeDataState.QrCodeStatus.qrCodeUnknown:
            return texts.unknownQrCode;
    }
}
