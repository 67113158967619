import {
    generateContextualReadStateSlice,
    generateServerReadStateSlice,
    generateWriteStateSlice,
    ServerReadState,
    ServerRequestState,
} from 'dg-web-shared/lib/ServerRequestStateSlices';
import { request, Response } from '../AsyncRequest';
import { Store } from 'dg-web-shared/lib/Flux';
import * as superagent from 'superagent';
import { LegacyRequest } from '../Http.ts';

export type EnforcementServerState<B> = ServerRequestState<B>;

export function generateEnforcementServerReadStateSlice<D>(config: {
    key: string;
    url: string;
    useSessionToken: boolean;
    onResponse?: (store: Store, state: EnforcementServerState<D>) => void;
    refreshEverySecs?: number;
}) {
    const refreshIds: number[] = [];
    const s = generateServerReadStateSlice<D>({
        key: config.key,
        sideEffects: (store: Store, state: ServerReadState<D>) => {
            if (state.shouldFetch) {
                store.update(
                    request({
                        req: () => superagent.get(`/ui-api/twint${config.url}`),
                        useAuthToken: config.useSessionToken,
                        requestWrite: (store: Store, res: Response) => {
                            s.setResponse(store, res);
                            const state = s.get(store);
                            if (config.onResponse) {
                                config.onResponse(store, state);
                            }
                            triggerRefreshsIfNecessary<D>(
                                res,
                                config.refreshEverySecs,
                                refreshIds,
                                state,
                                s.refetch,
                                store,
                            );
                            return config.key;
                        },
                    }),
                );
            }
        },
    });
    return {
        get: s.get,
        reset: (store: Store) => {
            const name = s.refetch(store);
            clearTimeouts(refreshIds);
            return name;
        },
        refetch: s.refetch,
    };
}
/**
 * @deprecated
 */
export function generateEnforcementContextualServerReadStateSlice<
    C extends Record<string, unknown>,
    D,
>(config: {
    key: string;
    req: (args: C) => LegacyRequest;
    onResponse?: (store: Store, state: EnforcementServerState<D>) => void;
    refreshEverySecs?: number;
}) {
    const refreshIds: number[] = [];
    const s = generateContextualReadStateSlice<C, D>({
        key: config.key,
        onShouldFetch: (
            store: Store,
            context: C,
            setResponse: (store: Store, response: Response) => void,
        ) => {
            store.update(
                request({
                    req: () => config.req(context),
                    useAuthToken: true,
                    requestWrite: (store: Store, res: Response) => {
                        setResponse(store, res);
                        const state = s.get(store, context);
                        if (config.onResponse) {
                            config.onResponse(store, state);
                        }
                        triggerRefreshsIfNecessary<D>(
                            res,
                            config.refreshEverySecs,
                            refreshIds,
                            state,
                            (store: Store) => {
                                store.update(store =>
                                    s.refetchSameContext(store, false),
                                );
                            },
                            store,
                        );
                        return config.key;
                    },
                }),
            );
        },
    });
    return {
        get: s.get,
        refetchSameContext: (store: Store, clearData: boolean) => {
            const name = s.refetchSameContext(store, clearData);
            clearTimeouts(refreshIds);
            return name;
        },
    };
}

const clearTimeouts = (refreshIds: number[]) => {
    refreshIds.forEach(id => window.clearTimeout(id));
    refreshIds.length = 0;
};

function triggerRefreshsIfNecessary<D>(
    res: Response,
    refreshEverySecs: number | undefined,
    refreshIds: number[],
    state: ServerRequestState<D, null>,
    refetch: (store: Store) => void,
    store: Store,
) {
    if (res.statusCode.cls.success && refreshEverySecs) {
        const refresh = () => {
            clearTimeouts(refreshIds);
            refreshIds.push(
                window.setTimeout(() => {
                    const timeSinceLastFetch =
                        new Date().getTime() -
                        (state.lastSuccessfulResponse || 0);
                    if (
                        timeSinceLastFetch > (refreshEverySecs || 0) * 1000 &&
                        state.status !== 'pending'
                    ) {
                        refetch(store);
                    } else {
                        refresh();
                    }
                }, 500),
            );
        };
        refresh();
    }
}

type OnResponseCallback<D> = (
    store: Store,
    state: EnforcementServerState<D>,
) => void;

/**
 * @deprecated
 */
export function generateEnforcementServerWriteStateSlice<P, D>(config: {
    key: string;
    req: (args?: P) => LegacyRequest;
    useSessionToken: boolean;
    onResponse?: OnResponseCallback<D>;
}) {
    const s = generateWriteStateSlice<D, null>({
        key: config.key,
    });

    const send = (
        store: Store,
        args?: P,
        onResponse?: OnResponseCallback<D>,
    ): string => {
        return request({
            req: config.req,
            useAuthToken: config.useSessionToken,
            requestWrite: (store: Store, res: Response) => {
                s.setResponse(store, res);
                if (config.onResponse) {
                    config.onResponse(store, s.get(store));
                }
                if (onResponse) {
                    onResponse(store, s.get(store));
                }
                return config.key;
            },
        })(store, args);
    };
    return {
        get: s.get,
        reset: s.reset,
        send: (store: Store, args?: P): string => {
            return send(store, args, undefined);
        },
    };
}
