import * as ReactDOM from 'react-dom';

import { localState } from 'dg-web-shared/lib/Flux';
import { Clickable, ClickEvent } from 'dg-web-shared/ui/Clickable';
import { css } from '@emotion/css';
import { texts } from '../i18n';
import { EnforcementServerState } from '../state/EnforcementStateSlices';
import { Spinner } from './Spinner';
import { Colors } from './vars';
import React from 'react';

export const Modal = (p: { children?: React.ReactNode }) => {
    if (document.getElementById('modal')) {
        return ReactDOM.createPortal(
            p.children,
            document.getElementById('modal') as HTMLElement,
        );
    } else {
        return null;
    }
};

interface AlertBoxedProps {
    confirmLabel: string;
    confirmColor: 'red' | 'blue';
    children?: JSX.Element | JSX.Element[];
    trigger: React.ReactElement;
    onConfirm?: (e?: ClickEvent) => void;
}

interface State {
    showConfirm: boolean;
}

export const AlertBoxed = localState<AlertBoxedProps, State>(
    { showConfirm: false },
    p => {
        const triggerElement = React.cloneElement(p.trigger, {
            onClick: p.onConfirm
                ? (e: React.SyntheticEvent<unknown>) => {
                      e.stopPropagation();
                      p.setState({ showConfirm: true });
                  }
                : undefined,
        });
        return (
            <div>
                {triggerElement}
                {p.state.showConfirm && (
                    <AlertBox
                        {...p}
                        onConfirm={e => {
                            if (p.onConfirm) {
                                p.onConfirm(e);
                            }
                            p.setState({ showConfirm: false });
                        }}
                        onCancel={() => p.setState({ showConfirm: false })}
                    />
                )}
            </div>
        );
    },
);

interface AlertBoxProps {
    onConfirm?: (e?: ClickEvent) => void;
    onCancel?: () => void;
    confirmLabel?: string;
    children?: React.ReactChild | React.ReactChild[];
    confirmColor?: 'red' | 'blue';
}

const buttonStyles: {
    height: string;
    lineHeight: string;
    textAlign: 'center';
    flex: string;
    padding: string;
    borderTop: string;
    whiteSpace: 'nowrap';
} = {
    height: '50px',
    lineHeight: '50px',
    textAlign: 'center',
    flex: '1 0 50%',
    padding: '0 24px',
    borderTop: `1px solid ${Colors.greyBackground}`,
    whiteSpace: 'nowrap',
};

export const AlertBox = (p: AlertBoxProps): JSX.Element => {
    const onConfirm = p.onConfirm;
    return (
        <Modal>
            <div
                className={css({
                    position: 'absolute',
                    zIndex: 1000,
                    fontSize: '16px',
                    fontFamily: 'roboto',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    background: 'rgba(4, 4 , 15, 0.4)',
                })}
            >
                <div
                    className={css({
                        background: Colors.white,
                        margin: '0 32px',
                        position: 'relative',
                        overflow: 'hidden', // to prevent visibility of borderLeft of second button
                        borderRadius: '3px',
                    })}
                >
                    <div
                        className={css({
                            padding: '24px 16px',
                            textAlign: 'center',
                        })}
                    >
                        {p.children}
                    </div>
                    <div className={css({ display: 'flex', flexWrap: 'wrap' })}>
                        {p.onCancel && (
                            <Clickable
                                element="div"
                                className={css(buttonStyles)}
                                onClick={p.onCancel}
                            >
                                {texts.cancel}
                            </Clickable>
                        )}
                        {onConfirm && (
                            <Clickable
                                className={css(buttonStyles, {
                                    borderLeft: `1px solid ${Colors.greyBackground}`,
                                    marginLeft: '-1px',
                                    color:
                                        p.confirmColor === 'red'
                                            ? Colors.red
                                            : Colors.lightBlue,
                                    fontWeight: 500,
                                })}
                                element="div"
                                onClick={e => onConfirm(e)}
                            >
                                {p.confirmLabel}
                            </Clickable>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export const PendingOrErrorModal = (p: {
    slices: EnforcementServerState<unknown>[];
    pendingOnlyWhenNoData?: boolean;
    showUseMeter?: boolean;
}) => {
    if (p.slices.filter(s => s.status === 'error').length > 0) {
        return <ErrorModal />;
    } else if (
        p.slices.filter(
            s =>
                s.status === 'pending' && (!p.pendingOnlyWhenNoData || !s.data),
        ).length > 0
    ) {
        return (
            <Modal>
                <div
                    className={css({
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        opacity: 0.8,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(255,255,255,0.9)',
                    })}
                >
                    <Spinner />
                </div>
            </Modal>
        );
    } else {
        return null;
    }
};

const ErrorModal = () => {
    return (
        <AlertBox
            onConfirm={() => {
                window.location.reload();
            }}
            confirmLabel={texts.reload}
        >
            {texts.masterDataError}
        </AlertBox>
    );
};
