import { Component } from 'react';
import { Login } from './Login';
import * as Flux from 'dg-web-shared/lib/Flux';
import * as AuthTokenState from '../state/AuthTokenState';
import { Enforcement } from './Enforcement';
import { injectGlobal } from '@emotion/css';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
injectGlobal`
html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    position: relative;
}

#app-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.AppWrapper {
    position: relative;
    height: 100%;
    max-width: 480px;
}

input[type="search"]::-webkit-search-decoration {
    display: none;
}
`;

interface Props {
    allState: Flux.Store;
}

const AppContainer = Flux.selectState(
    (store: Flux.Store) => {
        return { authToken: AuthTokenState.get(store) };
    },
    p => {
        if (p.authToken.authToken && p.authToken.authToken.length > 0) {
            return <Enforcement env={p.authToken.env} />;
        } else {
            return <Login {...p} />;
        }
    },
);

export class App extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <Flux.StoreContext.Provider value={this.props.allState}>
                <div className="AppWrapper">
                    <AppContainer />
                </div>
            </Flux.StoreContext.Provider>
        );
    }
}
