import { css } from '@emotion/css';
import { selectState, Store, Updater } from 'dg-web-shared/lib/Flux';
import {
    generateWriteStateSlice,
    RequestStatus,
    ServerRequestState,
} from 'dg-web-shared/lib/ServerRequestStateSlices';
import { request } from '../AsyncRequest';
import { texts } from '../i18n';
import { ChangePasswordForm } from './ChangePasswordForm';
import { slideInPortal, SlideInProps } from './SlideIn';
import { Spinner } from './Spinner';
import { ButtonContainer, TextButton } from './TextButton';
import { Colors, Typo } from './vars';
import { enforcementPut } from '../Http';

export function ChangePasswordController(props: Props): JSX.Element {
    return (
        <div>
            {props.remoteRequest.status === RequestStatus.NEVER_EXECUTED && (
                <ChangePasswordForm
                    onCancel={props.onClose}
                    onSave={props.httpPutNewPassword}
                />
            )}

            {props.remoteRequest.status === RequestStatus.PENDING && (
                <Spinner />
            )}

            {props.remoteRequest.status === RequestStatus.ERROR && (
                <div data-test="failure" className={errorStyle}>
                    {texts.passwordChangeFailed}
                </div>
            )}

            {props.remoteRequest.status === RequestStatus.SUCCESS && (
                <div data-test="success" className={messageStyle}>
                    {texts.passwordChangeSucceeded}
                </div>
            )}

            {(props.remoteRequest.status === RequestStatus.ERROR ||
                props.remoteRequest.status === RequestStatus.SUCCESS) && (
                <ButtonContainer>
                    <TextButton
                        data-test="ok"
                        onClick={props.onClose}
                        label={texts.close}
                    />
                </ButtonContainer>
            )}
        </div>
    );
}

export interface Props extends SlideInProps {
    httpPutNewPassword: (password: string) => void;
    remoteRequest: RemoteRequestState.State;
    update: Updater;
}

namespace RemoteRequestState {
    export type State = ServerRequestState<object>;

    export const { get, setResponse, reset } = generateWriteStateSlice<object>({
        key: 'change-password-controller-remote-request',
    });
}

const ConnectedChangePasswordController = selectState<
    SlideInProps,
    { remoteRequest: RemoteRequestState.State }
>(
    (store, _props) => ({
        remoteRequest: RemoteRequestState.get(store),
    }),
    props => (
        <ChangePasswordController
            {...props}
            httpPutNewPassword={makeHttpPutNewPassword(props.update)}
        />
    ),
    {
        willUnmount(store: Store) {
            RemoteRequestState.reset(store);
        },
    },
);

export const ChangePasswordSlideIn = slideInPortal<SlideInProps>(
    ConnectedChangePasswordController,
);

function makeHttpPutNewPassword(update: Updater): (password: string) => void {
    return password => {
        update(
            request({
                req: (data?: { password: string }) =>
                    enforcementPut('/operator', data!),

                useAuthToken: true,
                requestWrite: (store, res): string => {
                    RemoteRequestState.setResponse(store, res);
                    return 'change-password';
                },
            }),
            { password },
        );
    };
}

const messageStyle = css`
    ${Typo.robotoLight};
    font-size: 20px;
    font-weight: bold;
    margin: 60px 30px 24px 30px;
`;

const errorStyle = css`
    color: ${Colors.red};
    ${messageStyle};
`;
