import { localState, Store, Updater } from 'dg-web-shared/lib/Flux';
import * as EnforcementState from '../state/EnforcementState';
import * as MasterDataState from '../state/MasterDataState';
import { Clickable } from 'dg-web-shared/ui/Clickable';
import { getBridge } from '../Native';
import * as Icons48 from 'dg-web-shared/ui/icons/Icons48';
import * as Icons24 from 'dg-web-shared/ui/icons/Icons24';
import { SvgIcon } from 'dg-web-shared/ui/icons/SvgIcon';
import { IconProps } from 'dg-web-shared/ui/icons/Icon';
import { OnEnterContext, TextField } from './TextField';
import { texts } from '../i18n';
import { Env } from '../Http';
import { SlideInItem, slideInPortal } from './SlideIn';
import { css } from '@emotion/css';
import { Colors, Typo } from './vars';
import { rgba } from 'utils/src/Rgba.ts';
import React from 'react';

interface EnforcementConfigProps {
    form: EnforcementState.Form.State;
    layout: EnforcementState.Layout.State;
    masterData: MasterDataState.MasterData;
    context: EnforcementState.Context.State | null;
    env: Env;
    update: Updater;
}

const enforce = (store: Store, formState: EnforcementState.Form.State) => {
    EnforcementState.Context.writeFormState(store, formState);
    return 'enforce';
};

const switchToMode = (
    update: Updater,
    mode: EnforcementState.EnforcementMode,
): void => {
    update((store: Store): string => {
        EnforcementState.Context.reset(store);
        EnforcementState.Layout.stateWrite(store, {
            mode: mode,
        });
        EnforcementState.Form.stateWrite(store, {
            searchString: '',
        });
        return 'switch-to-' + mode;
    });
    const bridge = getBridge();
    if (bridge) {
        if (mode === 'lpr') {
            bridge.enableLPR();
        } else {
            bridge.disableLPR();
        }
    }
};

const getIcon = (mode: EnforcementState.EnforcementMode): JSX.Element => {
    switch (mode) {
        case 'lpr':
            return Icons48.lpr;
        case 'keyboard':
            return Icons48.keyboard;
        default:
            return Icons48.lpr;
    }
};

const ModeSwitch = (p: {
    layout: EnforcementState.Layout.State;
    update: Updater;
    mode: EnforcementState.EnforcementMode;
    allowedModes: EnforcementState.EnforcementMode[];
}) => {
    if (p.allowedModes.indexOf(p.mode) === -1) {
        return null;
    }
    const selected = p.layout.mode === p.mode;
    return (
        <Clickable
            element="div"
            className={css({
                flexGrow: 1,
                flexBasis: 0,
                flexShrink: 1,
                minWidth: 0,
                background: Colors.darkBlue,
                color: Colors.white,
                border: `1px solid ${Colors.border}`,
                '&:last-child': {
                    borderRadius: 0,
                },
                '&:first-child': {
                    borderLeft: 0,
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                },
                textAlign: 'center',
                lineHeight: '56px',
                ...Typo.robotoMedium,
                height: '56px',
                opacity: selected ? 1 : 0.6,
                borderBottom: 0,
                backgroundColor: selected ? Colors.lightBlue : Colors.darkBlue,
            })}
            onClick={() => {
                switchToMode(p.update, p.mode);
            }}
        >
            {p.mode === 'twint-qr-code' ? (
                'TWINT'
            ) : (
                <Icon48 icon={getIcon(p.mode)} />
            )}
        </Clickable>
    );
};

export const Icon48 = (props: IconProps): JSX.Element => (
    <div
        className={css({
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '4px',
            position: 'relative',
            width: '48px',
            height: '48px',
        })}
    >
        <SvgIcon {...props} width={48} height={48} />
    </div>
);

export const Icon24 = (props: IconProps): JSX.Element => (
    <div>
        <SvgIcon {...props} width={24} height={24} />
    </div>
);

class EntityInput extends React.Component<EnforcementConfigProps> {
    render() {
        return this.props.layout.mode === 'keyboard' ? (
            <div
                className={css({
                    display: 'flex',
                    alignItems: 'center',
                })}
            >
                <div className={css({ flexGrow: 1 })}>
                    <TextField
                        inputType="search"
                        // eslint-disable-next-line react/no-string-refs
                        ref="textField"
                        label={texts.searchHint}
                        value={this.props.form.searchString || ''}
                        focusOnMount
                        onChange={(licensePlateNumber: string) => {
                            this.props.update((store: Store): string => {
                                if (this.props.context) {
                                    EnforcementState.Context.reset(store);
                                }
                                EnforcementState.Form.stateWrite(store, {
                                    searchString: licensePlateNumber,
                                });
                                return 'LicensePlateInputChange';
                            });
                        }}
                        onEnter={(onEnterContext: OnEnterContext) => {
                            this.props.update(store =>
                                enforce(store, this.props.form),
                            );
                            onEnterContext.blur();
                        }}
                        onClear={
                            this.props.form.searchString &&
                            this.props.form.searchString.length > 0
                                ? () => {
                                      this.props.update(store =>
                                          EnforcementState.Form.stateWrite(
                                              store,
                                              {
                                                  searchString: '',
                                              },
                                          ),
                                      );
                                  }
                                : null
                        }
                    />
                </div>
                <Clickable
                    element="div"
                    className={css({
                        marginTop: '8px',
                        width: '36px',
                        height: '36px',
                        borderRadius: '50%',
                        background: Colors.darkBlue,
                        color: Colors.white,
                        marginRight: '16px',
                        boxShadow: `0 2px 6px ${rgba(Colors.darkBlue, 0.45)}`,
                    })}
                    onClick={() => {
                        this.props.update(store =>
                            enforce(store, this.props.form),
                        );
                        // eslint-disable-next-line react/no-string-refs
                        if (this.refs.textField) {
                            // eslint-disable-next-line react/no-string-refs
                            (this.refs.textField as TextField).blurInputField();
                        }
                    }}
                >
                    <Icon24 icon={Icons24.Enforcement._return} />
                </Clickable>
            </div>
        ) : null;
    }
}

interface ConfigSelectionProps {
    primary: string | null;
    secondary: string | null;
    onClick: () => void;
}

const ConfigSelection = (p: ConfigSelectionProps): JSX.Element => {
    return (
        <Clickable
            element="div"
            className={css({
                flexGrow: 1,
                flexBasis: 0,
                flexShrink: 1,
                minWidth: 0,
                background: Colors.darkBlue,
                color: Colors.white,
                border: `1px solid ${Colors.border}`,
                '&:last-child': {
                    borderRadius: 0,
                },
                '&:first-child': {
                    borderLeft: 0,
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                },
                height: '72px',
                ...Typo.robotoLight,
                padding: '0 16px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
            })}
            onClick={p.onClick}
        >
            <div
                className={css({
                    fontSize: '24px',
                    marginBottom: '4px',
                })}
            >
                {p.primary}
            </div>
            <div
                className={css({
                    fontSize: '14px',
                    marginBottom: '12px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                })}
            >
                {p.secondary}
            </div>
        </Clickable>
    );
};

interface LocalState {
    citySlideInOpen: boolean;
    zoneSlideInOpen: boolean;
}

export const EnforcementConfig = localState<EnforcementConfigProps, LocalState>(
    {
        citySlideInOpen: false,
        zoneSlideInOpen: false,
    },
    p => {
        const selectedZone = p.masterData.zones.filter(
            z => z.id === p.form.zoneId,
        )[0] || {
            name: '',
            zipCode: '',
            city: '',
            id: -1,
            extZoneCode: '',
        };
        const allowedModes = p.layout.allowedModes;
        return (
            <div className={css({ background: Colors.greyBackground })}>
                <EntityInput {...p} />
                <div className={css({ display: 'flex' })}>
                    <ConfigSelection
                        primary={selectedZone.zipCode}
                        secondary={selectedZone.city}
                        onClick={() => {
                            p.setState({ citySlideInOpen: true });
                        }}
                    />
                    <ConfigSelection
                        primary={
                            selectedZone.extZoneCode !== 0
                                ? selectedZone.extZoneCode.toString(10)
                                : ''
                        }
                        secondary={selectedZone.name}
                        onClick={() => {
                            p.setState({ zoneSlideInOpen: true });
                        }}
                    />
                    <CitySelectionSlideIn
                        title={texts.location}
                        open={p.state.citySlideInOpen}
                        onClose={() => p.setState({ citySlideInOpen: false })}
                        {...p}
                    />
                </div>
                <ZoneSelectionSlideIn
                    title={texts.zone}
                    open={p.state.zoneSlideInOpen}
                    onClose={() => p.setState({ zoneSlideInOpen: false })}
                    {...p}
                />
                <div className={css({ display: 'flex' })}>
                    <ModeSwitch
                        mode="lpr"
                        update={p.update}
                        layout={p.layout}
                        allowedModes={allowedModes}
                    />
                    <ModeSwitch
                        mode="keyboard"
                        update={p.update}
                        layout={p.layout}
                        allowedModes={allowedModes}
                    />
                    <ModeSwitch
                        mode="twint-qr-code"
                        update={p.update}
                        layout={p.layout}
                        allowedModes={allowedModes}
                    />
                </div>
            </div>
        );
    },
);

interface ConfigSelectionSlideInProps {
    open: boolean;
    onClose: () => void;
    title: string;
    masterData: MasterDataState.MasterData;
    form: EnforcementState.Form.State;
    update: Updater;
}

export const CitySelectionSlideIn = slideInPortal<ConfigSelectionSlideInProps>(
    p => {
        return (
            <div>
                {MasterDataState.getCities(p.masterData.zones).map(z => (
                    <SlideInItem
                        key={z.zipCode}
                        selected={z.zipCode === p.form.zipCode}
                        onClick={() =>
                            p.update((store: Store): string => {
                                EnforcementState.Form.stateWrite(store, {
                                    zipCode: z.zipCode,
                                    searchString: '',
                                    zoneId: MasterDataState.getZonesOfCity(
                                        z.zipCode,
                                        p.masterData.zones,
                                    )[0].id,
                                });
                                p.onClose();
                                EnforcementState.Context.reset(store);
                                return 'setCity';
                            })
                        }
                    >
                        {`${z.zipCode} ${z.city}`}
                    </SlideInItem>
                ))}
            </div>
        );
    },
);

export const ZoneSelectionSlideIn = slideInPortal<ConfigSelectionSlideInProps>(
    p => {
        return (
            <div>
                {MasterDataState.getZonesOfCity(
                    p.form.zipCode,
                    p.masterData.zones,
                ).map(z => (
                    <SlideInItem
                        key={z.id}
                        selected={z.id === p.form.zoneId}
                        onClick={() =>
                            p.update((store: Store): string => {
                                EnforcementState.Form.stateWrite(store, {
                                    zoneId: z.id,
                                    searchString: '',
                                });
                                p.onClose();
                                EnforcementState.Context.reset(store);
                                return 'setZone';
                            })
                        }
                    >
                        {(z.extZoneCode !== 0 ? z.extZoneCode + ' - ' : '') +
                            z.name}
                    </SlideInItem>
                ))}
            </div>
        );
    },
);
