import 'core-js/stable';

import { App } from './components/App';
import * as fluxInstance from './fluxInstance';
import { Store } from 'dg-web-shared/lib/Flux';
import * as EnforcementState from './state/EnforcementState';
import * as Update from './Update';
import * as Sentry from '@sentry/browser';
import { envIsProduction } from 'dg-web-shared/lib/Environment';
import * as Native from './Native';
import * as LocalStorage from 'dg-web-shared/lib/LocalStorage';
import { createRoot } from 'react-dom/client';
import { GeolocationState } from './state/GeolocationState';
import { createElement } from 'react';

export function initSentry(dsn: string) {
    Sentry.init({
        dsn: dsn,
        integrations: [
            Sentry.captureConsoleIntegration({
                levels: ['error'],
            }),
        ],
    });
}

if (envIsProduction()) {
    initSentry('https://d67d8e790cd94f38ac59de15bf0c60aa@sentry.io/147048');
}

Update.init(fluxInstance.flux);

const enforceLabel = (store: Store, label: string): string => {
    EnforcementState.Form.stateWrite(store, { searchString: label });
    EnforcementState.Context.writeFormState(
        store,
        EnforcementState.Form.get(store),
    );
    return 'enforceEntity';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(<any>window).messageFromNativeDevice = (
    type:
        | 'licensePlate'
        | 'nativeDevice'
        | 'location'
        | 'locationError'
        | 'nativeDeviceV2',
    message: string,
) => {
    fluxInstance.flux.update((store: Store): string => {
        if (EnforcementState.Form.get(store).searchString === message) {
            return 'searchString is message';
        }
        switch (type) {
            case 'licensePlate':
                return enforceLabel(store, message);
            case 'nativeDevice':
                LocalStorage.setStringItem(
                    LocalStorage.Items.enforcementNativeVersion,
                    message,
                );
                EnforcementState.Layout.stateWrite(store, {
                    mode: 'keyboard',
                    allowedModes: ['keyboard', 'lpr'],
                });
                return 'nativeVersionReport';
            case 'nativeDeviceV2':
                try {
                    EnforcementState.Version.stateWrite(
                        store,
                        JSON.parse(message),
                    );
                } catch {
                    // do nothing
                }

                // TODO: Remove when ALL users upgraded.
                LocalStorage.removeItem(
                    LocalStorage.Items.enforcementNativeVersion,
                );

                EnforcementState.Layout.stateWrite(store, {
                    mode: 'keyboard',
                    allowedModes: ['keyboard', 'lpr'],
                });
                return 'nativeVersionReportV2';
            case 'location':
                return GeolocationState.setCoordinatesIfChanged(
                    store,
                    JSON.parse(message),
                );
            case 'locationError':
                return GeolocationState.setError(store);
            default:
                return 'unknownMessageFromDevice';
        }
    });
};

const bridge = Native.getBridge();

if (bridge && bridge.requestDeviceInfoV2) {
    bridge.requestDeviceInfoV2();
} else if (bridge && bridge.requestDeviceInfo) {
    // TODO: Remove when ALL devices updated!
    bridge.requestDeviceInfo();
}

const container = document.getElementById('app-container');
const root = createRoot(container!);
root.render(
    createElement(App, {
        allState: fluxInstance.flux,
    }),
);
